import React from "react";
import {
  Button,
  Datagrid,
  List,
  NumberField,
  Pagination,
  ReferenceInput,
  SelectInput,
  TextField,
  TopToolbar,
} from "react-admin";
import dataProvider from "../../config/dataProvider";
import DownloadIcon from "@material-ui/icons/GetApp";
import { getCurrentFiscalYear } from "../../yearUtils";

const startYear = getCurrentFiscalYear() + 1;

const getFilters = (assetAccountId) => {
  return [
    <ReferenceInput
      label="Asset Account"
      source="assetAccountId"
      reference="assetAccounts"
      variant="outlined"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput
      label={"Department"}
      source="departmentId"
      reference="departments"
      variant="outlined"
      alwaysOn
      filterToQuery={() =>
        assetAccountId
          ? {
              assetAccountsFilter: assetAccountId,
            }
          : {}
      }
      key={assetAccountId}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>,
  ];
};

const currencyStyle = {
  style: "currency",
  currency: "USD",
  currencyDisplay: "narrowSymbol",
};

const rowStyle = (record) => {
  const isLastRow = record.budgetItemName === null;
  if (isLastRow)
    return {
      backgroundColor: "#f5f5f5",
    };
  return {};
};

const Actions = (props) => {
  return (
    <TopToolbar>
      <Button
        alignIcon="left"
        label="Summary Export"
        onClick={async () =>
          await dataProvider.downloadBudgetItemsGroupedSummaryReport(
            props.filterValues,
            props.currentSort
          )
        }
      >
        <DownloadIcon />
      </Button>
      <Button
        alignIcon="left"
        label="Export to Excel"
        onClick={async () =>
          await dataProvider.downloadBudgetItemsGroupedReport(
            props.filterValues,
            props.currentSort
          )
        }
      >
        <DownloadIcon />
      </Button>
    </TopToolbar>
  );
};

export const BudgetItemsGroupedList = (props) => {
  const searchParams = new URLSearchParams(props.location.search);
  const filterValues = JSON.parse(searchParams.get("filter") || "{}");
  const { data } = props;
  console.log("BudgetItemsGroupedList");
  console.log(data);
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={getFilters(filterValues?.assetAccountId)}
      exporter={false}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000, 10000]} />}
      actions={<Actions />}
      filter={{ historical: false }}
    >
      <Datagrid rowStyle={rowStyle}>
        <TextField
          sortBy="assetAccountId"
          source="assetAccount.name"
          label={"Asset Account"}
        />
        <TextField
          sortBy="department"
          source="department.name"
          label={"Department"}
        />
        <TextField
          sortBy="name"
          source="budgetItemName"
          label={"Budget Item Description"}
        />
        <NumberField
          source="nextYearJanuary"
          label={`${startYear} - Jan`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearFebruary"
          label={`${startYear} - Feb`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearMarch"
          label={`${startYear} - Mar`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearApril"
          label={`${startYear} - Apr`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearMay"
          label={`${startYear} - May`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearJune"
          label={`${startYear} - Jun`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearJuly"
          label={`${startYear} - Jul`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearAugust"
          label={`${startYear} - Aug`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearSeptember"
          label={`${startYear} - Sep`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearOctober"
          label={`${startYear} - Oct`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearNovember"
          label={`${startYear} - Nov`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearDecember"
          label={`${startYear} - Dec`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearTotal"
          label={`${startYear} (Total)`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearPlus1Estimate"
          label={`${startYear + 1} (Estimate)`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearPlus2Estimate"
          label={`${startYear + 2} (Estimate)`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearPlus3Estimate"
          label={`${startYear + 3} (Estimate)`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="nextYearPlus4Estimate"
          label={`${startYear + 4} (Estimate)`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
        <NumberField
          source="estimatedYearsTotal"
          label={`${startYear + 1}-${startYear + 4} (Estimate)`}
          options={currencyStyle}
          sortable={false}
          locales="en-US"
        />
      </Datagrid>
    </List>
  );
};
