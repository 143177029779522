import React from "react";
import Inbox from '@material-ui/icons/Inbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
    message: {
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '0 1em',
      color:
        theme.palette.type === 'light'
          ? 'inherit'
          : theme.palette.text.primary,
    },
    icon: {
      width: '9em',
      height: '9em',
    }
  })
);

const Empty = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.message}>
      <Inbox className={classes.icon} />
      <Typography variant="h4" paragraph>
        {props.msg}
      </Typography>
    </div>
  );
};

export default Empty;