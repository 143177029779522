import * as React from "react";
import { useContext } from 'react';
import ButtonMUI from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";
import { FlagContext } from '../../components/FlagContext';
import {
    Show,
    SimpleShowLayout,
    RichTextField,
    DateField,
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    DeleteButton,
    ReferenceInput,
    ReferenceField,
    SelectInput,
    SimpleForm,
    TextInput,
    SaveButton,
    Toolbar,
    DateInput,
    TopToolbar,
    useNotify,
    useRefresh,
    FunctionField,
    CreateButton,
    EditButton,
    FilterButton,
    Labeled,
    useRecordContext,
    useRedirect,
    Button,
    NumberInput,
    NumberField,
} from "react-admin";
import { parse } from "query-string";
import classnames from "classnames";
import { canI } from "../../config/rolePermissions";
import Grid from "@material-ui/core/Grid";
//import Typography from '@material-ui/core/Typography';
//import EditButtonIcon from '../../components/EditButtonIcon';
import SearchInput from "../../components/SearchInput";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CancelButton from "../../components/CancelButton";
import Cached from "@material-ui/icons/Cached";
import myDataProvider from "../../config/dataProvider";
/**
 * TODO: ellipsis to Title cell on List view: https://github.com/marmelab/react-admin/blob/master/examples/demo/src/reviews/ReviewListDesktop.tsx
 */

const STATUS_REQUEST = {
    1: "PENDING",
    2: "APPROVED",
    3: "REJECTED ",
};

const CHOISE_STATUS = [
    { id: 1, name: "PENDING" },
    { id: 2, name: "APPROVED" },
    { id: 3, name: "REJECTED" },
];

/*const useColoredStyles = makeStyles({
    PENDING: { color: '#002edd' },
    APPROVED: { color: '#00ab2e' },
    REJECTED: { color: '#dd0000' },
});*/

const useStatusStyles = makeStyles({
    statusApproved: {
        color: "#196F3D",
    },
    statusRejected: {
        color: "#922B21",
    },
    statusPending: {
        color: "#B9770E",
    },
});

const requestFilters = [
    <SearchInput
        key="search-input"
        source="q"
        label="Search"
        resettable
        alwaysOn
        variant="outlined"
    />,
    <TextInput source="title" variant="outlined" />,
    <ReferenceInput
        label="Department"
        source="departmentId"
        reference="departments"
        variant="outlined"
    >
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <DateInput
        label="Created date from"
        source="createdAt_gte"
        variant="outlined"
    />,
    <DateInput
        label="Created date to"
        source="createdAt_lt"
        variant="outlined"
    />,
    <SelectInput source="status" choices={CHOISE_STATUS} variant="outlined" />,
];

const RequestCreateToolbar = (props) => {
    const flag = useContext(FlagContext);
    const refresh = useRefresh();
    const notify = useNotify();
    return (
        flag === false ?
            <Toolbar {...props}>
                <CancelButton basePath={"/requests"} />
                <SaveButton
                    label="Save &amp; Back"
                    redirect="list"
                    submitOnEnter={true}
                />
                <SaveButton
                    label="Save &amp; Add"
                    redirect={false}
                    submitOnEnter={false}
                    variant="text"
                    onSuccess={() => {
                        notify("ra.notification.created", {
                            messageArgs: { smart_count: 1 },
                        });
                        refresh();
                    }}
                />
            </Toolbar>
        :
            <Toolbar {...props}>
                <CancelButton basePath={"/requests"} />
            </Toolbar>
    );
};

const validateRequestCreation = (values) => {
    const errors = {};
    if (!values.title) {
        errors.title = "The title is required";
    } else if (values.title.length > 100) {
        errors.title = "Too long";
    }
    if (!values.description) {
        errors.description = "The description is required";
    } else if (values.description.length > 500) {
        errors.description = "Too long";
    }
    if (!values.departmentId) {
        errors.departmentId = "The department is required";
    }
    return errors;
};

const validateRequestReassign = (values) => {
    const errors = {};
    if (!values.departmentId) {
        errors.departmentId = "The department is required";
    }
    return errors;
};

const useActionStyles = makeStyles((theme) => ({
    createButton: {
        marginLeft: theme.spacing(2),
        /*marginRight: theme.spacing(2),*/
    },
}));

const RequestListActions = () => {
    const classes = useActionStyles();
    const flag = useContext(FlagContext);
    return (
        <TopToolbar>
            <FilterButton />
            {
            flag === false && <CreateButton
                basePath="/requests"
                variant="contained"
                className={classes.createButton}
                icon={<AddBoxIcon />}
            />}
        </TopToolbar>
    );
};
/*
const useRequestListActionToolbarStyles = makeStyles({
    toolbar: {
        alignItems: 'center',
        display: 'flex',
        marginTop: -1,
        marginBottom: -1,
    },
});
*/
const RequestListActionToolbar = (props) => {
    //const classes = useRequestListActionToolbarStyles();
    const enablePendingActions = props.record && props.record.canIUpdateIt;
    const enableRollbackRejectedActions =
        props.record && props.record.canIRollbackReject;
    const enableDeleteActions = props.record && props.record.canIDeleteIt;

    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify("Request Deleted", "info", null, true);
        refresh();
    };

    const rollback = async (event) => {
        event.stopPropagation();
        await myDataProvider.rollbackReject(props.record.id);
        notify("Request status changed");
        refresh();
    };

    if (enableDeleteActions) {
        return (
            <DeleteButton
                title="Delete"
                label=""
                size="small"
                onSuccess={onSuccess}
                {...props}
            />
        );
    }

    if (enablePendingActions) {
        return (
            <>
                <EditButton title="Edit" label="" size="small" {...props} />
                <DeleteButton
                    title="Delete"
                    label=""
                    size="small"
                    onSuccess={onSuccess}
                    {...props}
                />
            </>
        );
    }

    if (enableRollbackRejectedActions) {
        return (
            <Button
                label=""
                title="Set on pending"
                onClick={(e) => rollback(e)}
            >
                <Cached />
            </Button>
        );
    }

    return null;
};

//por ahora no se usa. reemplazar el FunctionField de Status
// Solucionado con ColoredStatusField
/*const colored = WrappedComponent => props => {
    const classes = useColoredStyles();
    return (
        <WrappedComponent
            className={classnames({
                [classes.PENDING]: props.record[props.source] === 1,
                [classes.APPROVED]: props.record[props.source] === 2,
                [classes.REJECTED]: props.record[props.source] === 3,
            })}
            {...props}
        />
    )
};

const ColoredTextField = colored(TextField);
ColoredTextField.defaultProps = TextField.defaultProps;

const StatusFunctionField = (record) => {
    const classes = useColoredStyles();
    return (
        <Typography className={classnames({
            [classes.PENDING]: record.status === 1,
            [classes.APPROVED]: record.status === 2,
            [classes.REJECTED]: record.status === 3,
        })}>
            {STATUS_REQUEST[record.status]}
        </Typography>
    )
}
*/

const ColoredStatusField = (props) => {
    const classes = useStatusStyles();
    const status = props.record.status;

    return (
        <FunctionField
            label={props.label}
            render={(record) => `${STATUS_REQUEST[record.status]}`}
            addLabel={true}
            className={classnames({
                [classes.statusPending]: status === 1,
                [classes.statusApproved]: status === 2,
                [classes.statusRejected]: status === 3,
            })}
        />
    );
};

export const RequestList = (props) => {
    const isRequester = !canI(props.permissions, "requests", "listAllRequests");
    const flag = useContext(FlagContext);    
    return (
        <List
            {...props}
            filters={requestFilters}
            actions={<RequestListActions />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick="show">
                <TextField source="title" />
                <ReferenceField
                    link={false}
                    label="Assigned to"
                    source="departmentId"
                    reference="departments"
                >
                    <TextField source="name" />
                </ReferenceField>
                <NumberField
                    label="Estimated amount"
                    source="amount"
                    locales="en-US"
                    options={{
                        style: "currency",
                        currency: "USD",
                        currencyDisplay: "narrowSymbol",
                    }}
                />
                {!isRequester && (
                    <TextField label="Requester" source="creator.name" />
                )}
                <DateField
                    label="Created at"
                    source="createdAt"
                    locale="en-US"
                    options={{
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                    }}
                />
                <ColoredStatusField {...props} label={"Status"} />
                {flag === false && <RequestListActionToolbar />}
            </Datagrid>
        </List>
    );
};

export function RequestCreate(props) {
    const flag = useContext(FlagContext);
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify("Request created");
        redirect("/requests");
        refresh();
    };
    return (   
        flag === false ?     
            <Create
                {...props}
                onSuccess={onSuccess}
                transform={(data) => ({ ...data, amount: data.amount?.toString() })}
            >
                <SimpleForm
                    variant="standard"
                    validate={validateRequestCreation}
                    toolbar={<RequestCreateToolbar />}
                    //warnWhenUnsavedChanges //not working with validate.
                >
                    <TextInput source="title" variant="outlined" />
                    <TextInput multiline source="description" variant="outlined" />
                    <ReferenceInput
                        label="Department"
                        source="departmentId"
                        reference="departments"
                        variant="outlined"
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <NumberInput
                        source="amount"
                        variant="outlined"
                        min={0}
                        label="Estimated amount (USD)"
                    />
                </SimpleForm>
            </Create>
        :
            <Toolbar {...props}>
                <CancelButton basePath={"/requests"} />
            </Toolbar>
    );
}

const RequestTitle = ({ record }) => {
    return <span>Request {record ? `"${record.title}"` : ""}</span>;
};

const TextFieldInForm = ({ variant, ...props }) => <TextField {...props} />;
TextFieldInForm.defaultProps = TextField.defaultProps;

const RequestEditToolbar = (props) => (
    <Toolbar {...props}>
        <CancelButton basePath={"/requests"} />
        <SaveButton disabled={props.pristine || props.validating} />
    </Toolbar>
);
export const RequestEdit = (props) => {
    const flag = useContext(FlagContext);
    const { status } = parse(props.location.search);
    const reassign = status === "reassign";
    //const actions = reassign ? { actions: false } : {};

    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify("Request updated", "info", null, true);
        redirect("/requests");
        refresh();
    };
    const toolbar = reassign ? { toolbar: <RequestEditToolbar /> } : {};
    return (
        flag === false ?
            <Edit
                title={<RequestTitle />}
                {...props}
                actions=""
                onSuccess={onSuccess}
            >
                <SimpleForm
                    variant="standard"
                    validate={validateRequestCreation}
                    {...toolbar}
                    //warnWhenUnsavedChanges //not working with validate.
                >
                    {reassign ? (
                        <TextFieldInForm source="title" addLabel={false} />
                    ) : (
                        <TextInput source="title" variant="outlined" />
                    )}
                    {reassign ? (
                        <TextFieldInForm source="description" />
                    ) : (
                        <TextInput
                            multiline
                            source="description"
                            variant="outlined"
                        />
                    )}
                    <ReferenceInput
                        label="Department"
                        source="departmentId"
                        reference="departments"
                        variant="outlined"
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </SimpleForm>
            </Edit>
        :
            <TopToolbar>
                <CancelButton basePath={"/requests"} />
            </TopToolbar>
    );
};

const RequestShowStyles = makeStyles((theme) => ({
    root: {},
    actions: {
        marginLeft: theme.spacing(2),
    },
}));

const RedColorButton = withStyles((theme) => ({
    root: {
        color: red[500],
        borderColor: red[500],
        "&:hover": {
            borderColor: red[700],
            color: theme.palette.getContrastText(green[700]),
            backgroundColor: red[700],
        },
    },
}))(ButtonMUI);

const GreenColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[700]),
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
}))(ButtonMUI);

const RequestShowActions = ({ classes, record }) => {
    const flag = useContext(FlagContext);
    if (record && record.canIValidateIt && record.status === 1)
        return (
            flag === false ?
                <TopToolbar>
                    <CancelButton basePath={"/requests"} />
                    <RedColorButton
                        color="primary"
                        component={Link}
                        to={`/budgetItems/create/?requestId=${
                            record && record.id ? record.id : ""
                        }&status=reject`}
                        variant="outlined"
                        style={{ marginLeft: ".4rem" }}
                    >
                        Reject
                    </RedColorButton>
                    <ButtonMUI
                        color="secondary"
                        component={Link}
                        to={`/requests/${
                            record && record.id ? record.id : ""
                        }?status=reassign`}
                        variant="contained"
                        className={classes.actions}
                    >
                        Reassign
                    </ButtonMUI>
                    <GreenColorButton
                        color="primary"
                        component={Link}
                        to={`/budgetItems/create?requestId=${
                            record && record.id ? record.id : ""
                        }&status=approve`}
                        variant="contained"
                        className={classes.actions}
                    >
                        Accept
                    </GreenColorButton>
                    
                </TopToolbar>
            :
                <TopToolbar>
                    <CancelButton basePath={"/requests"} />
                </TopToolbar>
        );

    return null;
};

const ConditionalCommentField = (props) => {
    const record = useRecordContext(props);

    return record && record.status === 3 ? (
        <Labeled label="Rejection justification">
            <TextField label="Comment" source="comment" {...props} />
        </Labeled>
    ) : null;
};

export const RequestShow = (props) => {    
    const classes = RequestShowStyles();
    const actions =
        props.permissions && canI(props.permissions, "requests", "approve") ? (
            <RequestShowActions classes={classes} />
        ) : null;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={6}>
                <Show
                    {...props}
                    title={"Request revision"}
                    actions={false}
                    className={classes.root}
                >
                    <SimpleShowLayout>
                        <TextField source="title" />
                        <RichTextField source="description" />
                        {/*<DateField label="Created date" source="createdAt" />*/}
                        <ReferenceField
                            link={false}
                            label="Department"
                            source="departmentId"
                            reference="departments"
                        >
                            <TextField source="name" />
                        </ReferenceField>

                        <NumberField
                            label="Estimated amount"
                            source="amount"
                            locales="en-US"
                            options={{
                                style: "currency",
                                currency: "USD",
                                currencyDisplay: "narrowSymbol",
                            }}
                            emptyText="N/A"
                        />

                        <ConditionalCommentField />

                        {actions}
                    </SimpleShowLayout>
                </Show>
            </Grid>
        </Grid>
    );
};

export function RequestReassign(props) {
    return (
        <Edit {...props}>
            <SimpleForm
                variant="standard"
                validate={validateRequestReassign}
                //warnWhenUnsavedChanges //not working with validate.
            >
                <ReferenceInput
                    label="Department"
                    source="departmentId"
                    reference="departments"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <SaveButton />
            </SimpleForm>
        </Edit>
    );
}
