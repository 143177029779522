import React, { createContext, useState, useEffect } from 'react';
import dataProvider from "../config/dataProvider.js";
export const FlagContext = createContext();

export const fetchFlag = async () => {
  try {
    const response = await dataProvider.getFreezeStatus();
    return response.data;
  } catch (error) {
    console.error('Error al obtener el flag:', error);
    return true;
  }
};

export const FlagProvider = ({ children }) => {
  const [flag, setFlag] = useState(null);

  useEffect(() => {
    const initializeFlag = async () => {
        const fetchedFlag = await fetchFlag();
        setFlag(fetchedFlag);
    };

    initializeFlag();
  }, []);

  return (
    <FlagContext.Provider value={flag}>
      {children}
    </FlagContext.Provider>
  );
};