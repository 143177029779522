import React from "react";
import CustomAppBar from './CustomAppBar';
import { Layout, Menu, MenuItemLink } from 'react-admin';
import { useGetResourceLabel, getResources } from 'ra-core';
import { shallowEqual, useSelector } from 'react-redux';
import DefaultIcon from '@material-ui/icons/ViewList';
import SidebarToggleButton from "./SidebarToggleButton";
import { makeStyles } from '@material-ui/core/styles';
import dataProvider from "../config/dataProvider.js";
import { Button } from "@material-ui/core";
import { useContext, useState, useEffect } from "react";
import { FlagContext } from "./FlagContext";
import AcUnitIcon from '@material-ui/icons/AcUnit';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import { useMsal } from "@azure/msal-react";

const useStyles = makeStyles(
  theme => ({
    menuButtonIconClosed: {},
    menuButtonIconOpen: {},
    menuButtonOpen: {
      alignSelf: "flex-end",
      marginBottom: "50px"
    },
    menuButtonClosed: {
      marginBottom: "50px"
    },  
    logo: {
      maxWidth: 80,
      margin: 5,
      alignSelf: "center"
    },
    buttonRoot: {
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    buttonHover: {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  }),
  { name: 'RaMenu' }
);

const CustomMenu = ({ dense, ...props }) => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const flag = useContext(FlagContext);
  const classes = useStyles(props);
  const resources = useSelector(getResources, shallowEqual);
  const getResourceLabel = useGetResourceLabel();
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDiv(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const sidebarToggleButtonClasses = {
    menuButtonIconClosed: classes.menuButtonIconClosed,
    menuButtonIconOpen: classes.menuButtonIconOpen,
  };

  const modifyFreeze = async () => {
    await dataProvider.modifyFreeze(flag); 
    window.location.reload();
  };

  return (
    <Menu {...props}>
       <img className={classes.logo}
            src={
              "/vtx1logo.svg"
            }
            alt="Logo"
        />
                                        
      <SidebarToggleButton
        classNameOpen={classes.menuButtonOpen}
        classNameClosed={classes.menuButtonClosed}
        classes={sidebarToggleButtonClasses}
      />
      {resources
        .filter(r => r.hasList)
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={{
              pathname: `/${resource.name}`,
              state: { _scrollToTop: true },
            }}
            primaryText={getResourceLabel(resource.name, 2)}
            leftIcon={
              resource.icon ? (
                <resource.icon />
              ) : (
                <DefaultIcon />
              )
            }
            dense={dense}
          />
        ))}
      {showDiv && account.idTokenClaims.roles.includes("admin") && (
        <div style={{paddingTop: 80}}>
          <Button 
            style={{
              color: "white", 
              fontWeight: "bolder", 
              fontSize: "1rem", 
              width: "100%",
            }} 
            onClick={() => modifyFreeze()}
            startIcon={flag === false ? <AcUnitIcon /> : <WhatshotIcon />}
            classes={{ root: classes.buttonRoot, hover: classes.buttonHover }}
          >
            {flag === true ? "UNFREEZE" : "FREEZE"}
          </Button>
        </div>
      )}
    </Menu>
  )
};

const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />;

export default CustomLayout;