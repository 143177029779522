import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import ApartmentIcon from "@material-ui/icons/ApartmentOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import WorkspacesOutlinedIcon from "@material-ui/icons/FilterNoneOutlined";
import HistoryOulinedIcon from "@material-ui/icons/HistoryOutlined";
import LibraryAddCheckOutlinedIcon from "@material-ui/icons/LibraryAddCheckOutlined";
import LibraryAddOutlinedIcon from "@material-ui/icons/LibraryAddOutlined";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
import * as React from "react";
import { Admin, Resource } from "react-admin";
import {
  AssetAccountsCreate,
  AssetAccountsEdit,
  AssetAccountsList,
} from "../pages/assetAccounts";
import {
  BudgetItemCreate,
  BudgetItemEdit,
  BudgetItemList,
  BudgetItemShow,
} from "../pages/budgetitems";
import {
  DepartmentCreate,
  DepartmentEdit,
  DepartmentList,
  DepartmentShow,
} from "../pages/departments";
import {
  RequestCreate,
  RequestEdit,
  RequestList,
  RequestShow,
} from "../pages/requests";
//import customRoutes from '../config/customRoutes'
import dataProvider from "../config/dataProvider.js";
//import i18nProvider from "../config/i18nProvider.js"

import { useMsal } from "@azure/msal-react";
import CustomLayout from "../components/CustomLayout";
import LogoutButton from "../components/LogoutButton";
import { canI } from "../config/rolePermissions";
import theme from "../layout/theme.js";
import { BudgetItemsGroupedList } from "./budgetItemsGrouped";
import { CompaniesList, CompanyCreate, CompanyEdit } from "./companies";
import { fetchFlag } from "../components/FlagContext";
import CompaniesReportsLogo from "../pages/companiesReports/CompaniesReportsLogo.jsx";
import GLReportsLogo from "./glReports/GLReportsLogo.jsx";
import { GLReportsList } from "../pages/glReports";
import { CompaniesReportList } from "../pages/companiesReports";

const AdminProvider = ({ children }) => {
  const { instance } = useMsal();
  const authProvider = {
    // authentication
    login: (params) => instance.loginRedirect(), // no se usa nunca
    checkError: async (error) => {
      if (error.errorCode === "login_required") {
        await instance.loginRedirect();
        return Promise.reject();
      }
      return Promise.resolve();
    },
    checkAuth: () => {
      const account = instance.getActiveAccount();
      fetchFlag();
      if (!account) return Promise.reject();

      return Promise.resolve();
    },
    logout: () => {
      instance.logoutRedirect();
      return Promise.resolve(false);
    },
    getIdentity: async () => {
      try {
        const account = instance.getActiveAccount();
        return Promise.resolve({
          id: account.homeAccountId,
          fullName: account.name,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // authorization
    getPermissions: async () => {
      try {
        const account = instance.getActiveAccount();
        if (account.idTokenClaims.roles) {
          // si el user es nuevo es necesario q este service se llame para crerlo en la db, solo lo crea si no fue creado
          if (!localStorage.getItem(account.localAccountId + "-checked")) {
            localStorage.setItem(
              account.localAccountId + "-checked",
              account.localAccountId
            );

            dataProvider.checkUser();
          }
          return Promise.resolve(account.idTokenClaims.roles);
        }
        return Promise.reject();
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      //i18nProvider={i18nProvider}
      theme={theme}
      logoutButton={LogoutButton}
      layout={CustomLayout}
      //locale="en"
      //customRoutes={customRoutes} test para el approve q fallo
    >
      {children}
    </Admin>
  );
};

const App = () => {
  return (
    <AdminProvider>
      {(permissions) => {
        const resource = (
          name,
          list = null,
          edit = null,
          create = null,
          show = null,
          icon = null,
          options = null
        ) => {
          var newResource = {
            name: name,
          };
          if (list && canI(permissions, name, "list")) newResource.list = list;
          if (edit && canI(permissions, name, "edit")) newResource.edit = edit;
          if (create && canI(permissions, name, "create"))
            newResource.create = create;
          if (show && canI(permissions, name, "show")) newResource.show = show;
          if (icon) newResource.icon = icon;
          if (options) newResource.options = options;

          if (
            Object.keys(newResource).length === 1 &&
            !canI(permissions, name)
          ) {
            return null;
          }

          return <Resource {...newResource} />;
        };

        return [
          resource(
            "requests",
            RequestList,
            RequestEdit,
            RequestCreate,
            RequestShow,
            LibraryAddOutlinedIcon
          ),
          resource(
            "budgetItems",
            BudgetItemList,
            BudgetItemEdit,
            BudgetItemCreate,
            BudgetItemShow,
            LibraryAddCheckOutlinedIcon,
            { label: "Budget Items" }
          ),
          resource(
            "budgetItemsGrouped",
            BudgetItemsGroupedList,
            null,
            null,
            null,
            WorkspacesOutlinedIcon,
            { label: "Budget Items Grouped" }
          ),
          resource(
            "budgetItemsHistorical",
            BudgetItemList,
            null,
            null,
            null,
            HistoryOulinedIcon,
            { label: "Budget Items Historical" }
          ),
          resource(
            "assetAccounts",
            AssetAccountsList,
            AssetAccountsEdit,
            AssetAccountsCreate,
            null,
            LibraryBooksOutlinedIcon,
            { label: "Asset Accounts" }
          ),
          resource(
            "departments",
            DepartmentList,
            DepartmentEdit,
            DepartmentCreate,
            DepartmentShow,
            AccountTreeOutlinedIcon
          ),
          resource(
            "glReports",
            GLReportsList,
            null,
            null,
            null,
            GLReportsLogo,
            {
              label: "GL Reports",
            }
          ),
          resource(
            "companiesReports",
            CompaniesReportList,
            null,
            null,
            null,
            CompaniesReportsLogo,
            { label: "Company Reports" }
          ),
          resource("users", null, null, null, null, AssessmentOutlinedIcon),
          resource(
            "companies",
            CompaniesList,
            CompanyEdit,
            CompanyCreate,
            null,
            ApartmentIcon
          ),
        ];
      }}
    </AdminProvider>
  );
};

export default App;
