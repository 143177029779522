import React, { Children, memo, useContext, useState, useEffect } from "react";
import {/* AppBar,*/ LoadingIndicator } from 'react-admin';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  useMediaQuery,
  Slide,
  useScrollTrigger
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DRAWER_WIDTH, CLOSED_DRAWER_WIDTH } from 'ra-ui-materialui/lib/layout/Sidebar.js';
import CustomUserMenu from "./CustomUserMenu";
import SidebarToggleButton from "./SidebarToggleButton";
import { FlagContext } from "./FlagContext";

const useStyles = makeStyles(
  theme => ({
    root: {},
    appbar: {
      [theme.breakpoints.up('sm')]: {
        width: (props) =>
          props.open
            ? `calc(100% - ${DRAWER_WIDTH}px)`
            : `calc(100% - ${CLOSED_DRAWER_WIDTH}px)`,
        boxShadow: "none",
        marginTop: theme.spacing(2)
      },
      [theme.breakpoints.only('xs')]: {
        width: "100%"
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    toolbar: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
      },
      paddingRight: theme.spacing(3),
    },
    menuButton: {
      marginLeft: '0.2em',
      marginRight: '0.2em',
    },
    menuButtonIconClosed: {},
    menuButtonIconOpen: {},
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: "2rem",
      fontWeight: "bold",
      [theme.breakpoints.only('xs')]: {
        fontSize: "1.2rem",
      },
    },
    flagTitle: {
      textAlign: 'center',
      color: 'red',
    },
  }),
  { name: 'RaAppBar' }
);

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({ threshold: 10, disableHysteresis: true });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const CustomAppBar = props => {
  const {
    children,
    logout,
    open,
    title,
    ...rest
  } = props;

  const flag = useContext(FlagContext);
  const [showFreezeText, setShowFreezeText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFreezeText(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  const classes = useStyles(props);

  const isXSmall = useMediaQuery(theme =>
    theme.breakpoints.down('xs')
  );
  const sidebarToggleButtonClasses = {
    menuButtonIconClosed: classes.menuButtonIconClosed,
    menuButtonIconOpen: classes.menuButtonIconOpen,
  };
  return (
    <HideOnScroll className={classes.root}>
      <MuiAppBar className={classes.appbar} color={isXSmall ? "secondary" : "transparent"} {...rest}>
        <Toolbar
          disableGutters
          variant={isXSmall ? 'regular' : 'dense'}
          className={classes.toolbar}
        >
          {isXSmall && <SidebarToggleButton
            classNameOpen={classes.menuButton}
            classNameClosed={classes.menuButton}
            classes={sidebarToggleButtonClasses}
          />}

          {Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
          ) : (
            children
          )}
          {showFreezeText && 
            <Typography 
              id="flag-title" 
              variant="subtitle1" 
              className={classes.flagTitle}
            >
              {flag === true ? "The application is currently freezed. You can't create/edit/delete a Budget item or a Request" : ""}
            </Typography>
          }
          <LoadingIndicator />
          <CustomUserMenu logout={logout} />
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  )
};

export default memo(CustomAppBar);