const dataFake = {
  "requests": [
    {
      "id": "08d9b8e3-ee40-4ac6-8e78-6128baff91f2",
      "description": "My team needs 15 computers",
      "title": "15 Notebooks",
      "status": 1,
      "departmentId": 1,
      "creatorId": "08d9af86-cbc1-4345-8b7e-81f984e77268",
      "creator": {
        "id": "08d9af86-cbc1-4345-8b7e-81f984e77268",
        "email": "requester1@cwolmedow3americas.onmicrosoft.com",
        "name": "Requester 1",
        "description": null,
        "approvals": null
      },
      "createdAt": "2021-12-06T18:12:14.393693",
      "updatedAt": "2021-12-06T18:12:14.393696",
      "canIValidateIt": true
    },
    {
      "id": 1,
      "title": "Request item 1 title",
      "description": "Request item 1",
      "status": 1,
      "departmentId": 1,
      "createdAt": "2021-01-01T00:00:01",
      "updatedAt": "2021-01-01T00:00:02"
    },
    {
      "id": 2,
      "title": "Request item 2 title",
      "description": "Request item 2",
      "status": 1,
      "departmentId": 2,
      "createdAt": "2021-01-02T00:00:03",
      "updatedAt": "2021-01-02T00:00:04"
    },
    {
      "id": 3,
      "title": "Request item 3 title",
      "description": "Request item 3",
      "status": 2,
      "departmentId": 2,
      "createdAt": "2021-01-03T00:00:05",
      "updatedAt": "2021-01-04T00:00:06"
    },
    {
      "id": 4,
      "title": "Request item 4 title",
      "description": "Request item 4",
      "status": 2,
      "departmentId": 1,
      "createdAt": "2021-05-01T00:00:03",
      "updatedAt": "2021-06-01T00:00:04"
    },
    {
      "id": 5,
      "title": "Request item 5 title",
      "description": "Request item 5",
      "status": 3,
      "departmentId": 4,
      "createdAt": "2021-06-01T00:00:07",
      "updatedAt": "2021-07-01T00:00:08"
    },
    {
      "id": 6,
      "title": "Request item 6 title",
      "description": "Request item 6",
      "status": 1,
      "departmentId": 3,
      "createdAt": "2021-07-01T00:00:09",
      "updatedAt": "2021-07-01T00:00:10"
    }
  ],
  "departments": [
    {
      "id": 1,
      "name": "Department A",
      "description": "Department description 1",
      "date": "2021-01-01T00:00:05",
      "status": 1,
      "creatorId": null,
      "approverId": "111",
      "createdAt": "2021-11-15T19:48:21.641236",
      "updatedAt": "2021-11-15T19:48:21.641238"
    },
    {
      "id": 2,
      "name": "Department B",
      "description": "Department description 2",
      "date": "2021-01-01T00:00:06",
      "status": 1,
      "creatorId": "222",
      "approverId": "222",
      "createdAt": "2021-11-15T19:48:21.631330",
      "updatedAt": "2021-11-15T19:48:21.632694"
    },
    {
      "id": 3,
      "name": "Department C",
      "description": "Department description 3",
      "date": "2021-01-01T00:00:05",
      "status": 1,
      "creatorId": null,
      "approverId": "333",
      "createdAt": "2021-11-15T19:48:22.641236",
      "updatedAt": "2021-11-15T19:48:22.641238"
    },
    {
      "id": 4,
      "name": "Department D",
      "description": "Department description 4",
      "date": "2021-01-01T00:00:06",
      "status": 1,
      "creatorId": "08d9a88a-0632-4e36-8407-af253f251960",
      "approverId": "444",
      "createdAt": "2021-11-15T19:49:21.631330",
      "updatedAt": "2021-11-15T19:49:21.632694"
    }
  ]
};


export default dataFake;