import { Box, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { FlagContext } from "../../components/FlagContext";
import React, { useEffect, useState } from "react";
import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DeleteWithUndoButton,
  Edit,
  EditButton,
  Error,
  FilterButton,
  FormDataConsumer,
  FunctionField,
  List,
  Loading,
  NumberField,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  RichTextField,
  SaveButton,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useGetOne,
  useNotify,
  usePermissions,
  useRedirect,
  useRefresh,
} from "react-admin";
//import { Redirect } from 'react-router-dom';
import { parse } from "query-string";
import Empty from "../../components/Empty";
import SearchInput from "../../components/SearchInput";
//import { DateInput } from '../../components/DateInput';
import AddBoxIcon from "@material-ui/icons/AddBox";
import CancelButton from "../../components/CancelButton";
import { canI } from "../../config/rolePermissions";
//import { useFormState } from 'react-final-form';
import dataProvider from "../../config/dataProvider.js";
import { useIsHistorical } from "../../hooks/useIsHistorical";
import { useContext } from "react";

//TODO: Amounts to mui-currency-input? https://unicef.github.io/material-ui-currency-textfield/

const fullYear = new Date().getFullYear(); //TODO: Move to global var or restful
const isAfterJanuary = new Date().getMonth() > 0;

const getMonthName = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
    default:
      return "Unknown";
  }
};

const Month_CHOICES = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

const HISTORICAL_YEAR_CHOICES = [
  { id: 1, value: fullYear - 1 },
  { id: 2, value: fullYear - 2 },
  { id: 3, value: fullYear - 3 },
  { id: 4, value: fullYear - 4 },
];

const YEAR_CHOICES = [
  { id: 1, value: fullYear },
  { id: 2, value: fullYear + 1 },
  { id: 3, value: fullYear + 2 },
  { id: 4, value: fullYear + 3 },
  { id: 5, value: fullYear + 4 },
];

const validateApproveRequest = (values) => {
  const errors = {};
  if (!values.assetAccountId) {
    errors.assetAccountId = "GL account is required";
  }
  if (!values.name) {
    errors.name = "Budget Item Name is required";
  }
  return errors;
};

const validateEditBudgetItem = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Budget Item Name is required";
  }
  if (!values.year) {
    errors.year = "Year is required";
  }
  if (!values.departmentId) {
    errors.year = "Department is required";
  }
  if (!values.Month) {
    errors.Month = "Month is required";
  }
  if (!values.value) {
    errors.value = "Amount is required";
  } else if (values.value > 99999999) {
    errors.value = "The amount must be less than $99,999,999";
  }
  return errors;
};

const validateRejectRequest = (values) => {
  const errors = {};
  if (!values.comment) {
    errors.comment = "Reason is required";
  }
  return errors;
};

const budgetItemFilters = [
  <SearchInput
    key="search-input-budgetItem"
    source="q"
    label="Search"
    resettable
    alwaysOn
    variant="outlined"
  />,
  <ReferenceInput
    label="GL account"
    source="assetAccountByName"
    reference="assetAccounts"
    variant="outlined"
    filter={{ forFilterDropdown: "true" }}
  >
    <SelectInput />
  </ReferenceInput>,

  <ReferenceInput
    label="Company"
    source="assetAccount--companyId"
    reference="companies"
    variant="outlined"
  >
    <SelectInput optionText={"name"} />
  </ReferenceInput>,

  <TextInput source="name" label="Description" variant="outlined" />,
  <TextInput source="createdAt" label="Created at" variant="outlined" />,
  <ReferenceInput
    label="Department"
    source="departmentId"
    reference="departments"
    variant="outlined"
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];
/*
const useActionStyles = makeStyles(theme => ({
    createButton: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));
*/
const useActionStyles = makeStyles((theme) => ({
  createButton: {
    marginLeft: theme.spacing(2),
  },
}));
const BudgetItemListActions = () => {
  const flag = useContext(FlagContext);
  const historical = useIsHistorical();
  const classes = useActionStyles();
  const { loading, permissions } = usePermissions();
  return (
    <TopToolbar>
      <FilterButton />
      {!loading &&
        !historical &&
        canI(permissions, "budgetItems", "create") &&
        flag === false && (
          <CreateButton
            className={classes.createButton}
            icon={<AddBoxIcon />}
            variant="contained"
          />
        )}
    </TopToolbar>
  );
};

const useListStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: "nowrap",
  },
}));

const ListPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 1000, 10000]} />
);

export const BudgetItemList = (props) => {
  const classes = useListStyles();
  const flag = useContext(FlagContext);
  const emptyMsg = !canI(props.permissions, "budgetItems", "create")
    ? { empty: <Empty msg="No Budget Items yet." /> }
    : {};

  const historical = useIsHistorical();

  return (
    <List
      {...emptyMsg}
      {...props}
      filters={[
        ...budgetItemFilters,
        <SelectInput
          source="year"
          choices={historical ? HISTORICAL_YEAR_CHOICES : YEAR_CHOICES}
          optionText={(choices) => String(choices.value)}
          optionValue="value"
          variant="outlined"
        />,
      ]}
      filterDefaultValues={
        historical
          ? undefined
          : {
              year: isAfterJanuary ? fullYear + 1 : fullYear,
            }
      }
      actions={<BudgetItemListActions />}
      bulkActionButtons={false}
      pagination={<ListPagination />}
      filter={{ historical }}
    >
      <Datagrid>
        {/*<TextField source="id" />*/}
        <ReferenceField
          link={false}
          label="GL account"
          source="assetAccountId"
          reference="assetAccounts"
        >
          <TextField source="name" className={classes.root} />
        </ReferenceField>
        <TextField label="Description" source="name" />
        <TextField label={"Company"} source={"assetAccount.company.name"} />

        <TextField source="year" />
        <FunctionField
          source="Month"
          render={(record) => getMonthName(record.month)}
        />
        <NumberField
          label="Amount"
          source="value"
          locales="en-US"
          options={{
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol",
          }}
        />
        <ReferenceField
          link={false}
          label="Department"
          source="departmentId"
          reference="departments"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField
          label="Created at"
          source="createdAt"
          locale="en-US"
          options={{
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }}
        />
        {/*<TextField source="status" />*/}
        <FunctionField
          render={(record) => (
            <div style={{ display: "flex", gap: "1rem" }}>
              {props.permissions && flag === false && (
                <>
                  {canI(props.permissions, "budgetItems", "edit") &&
                    record?.editable && (
                      <EditButton
                        label=""
                        title="Edit"
                        size="small"
                        record={record}
                      />
                    )}

                  {canI(props.permissions, "budgetItems", "delete") &&
                    record?.editable && (
                      <DeleteWithUndoButton record={record} label="" />
                    )}
                </>
              )}
            </div>
          )}
        />
      </Datagrid>
    </List>
  );
};

const BudgetItemTitle = ({ record }) => {
  return <span>Budget Item {record ? `"${record.name}"` : ""}</span>;
};

const ToolbarEditActions = ({ notifyMsg, ...props }) => {
  //const redirect = useRedirect();
  //const notify = useNotify();
  //const refresh = useRefresh();
  return (
    <Toolbar {...props}>
      <CancelButton basePath={"/budgetitems"} />
      <SaveButton
        disabled={props.pristine || props.validating}
        /*
                onSuccess={() => {
                    redirect('list', props.basePath);
                    notify(notifyMsg, { messageArgs: { smart_count: 1 } });
                    refresh();
                }}*/
      />
    </Toolbar>
  );
};

export const BudgetItemEdit = (props) => {
  const flag = useContext(FlagContext);
  const { data, loaded } = useGetOne("budgetItems", props.id);

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  if (!loaded) return <Loading />;

  const onSuccess = () => {
    notify("Budget Item updated", "info", null, true);
    redirect("/budgetitems");
    refresh();
  };

  return flag === false ? (
    <Edit
      title={<BudgetItemTitle />}
      actions={null}
      {...props}
      onSuccess={onSuccess}
    >
      <SimpleForm
        variant="outlined"
        warnWhenUnsavedChanges
        toolbar={<ToolbarEditActions notifyMsg={`${data.name} updated`} />}
        validate={validateEditBudgetItem}
      >
        <ReferenceInput
          label="Asset Account"
          source="assetAccountId"
          reference="assetAccounts"
          filter={{ departmentId: data.departmentId }}
          variant="outlined"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" variant="outlined" />
        <SelectInput
          source="year"
          choices={YEAR_CHOICES}
          optionText={(choices) => String(choices.value)}
          optionValue="value"
          variant="outlined"
        />
        <SelectInput
          source="Month"
          choices={Month_CHOICES}
          optionText="name"
          optionValue="id"
          variant="outlined"
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="Amount"
          source="value"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
      </SimpleForm>
    </Edit>
  ) : (
    <Toolbar {...props}>
      <CancelButton basePath={"/budgetItems"} />
    </Toolbar>
  );
};

const BudgetItemCreateToolbar = ({ notifyMsg, ...props }) => {
  const redirect = useRedirect();
  const notify = useNotify();
  return (
    <Toolbar {...props}>
      <SaveButton
        label="Save"
        redirect={false}
        submitOnEnter={false}
        onSuccess={() => {
          redirect("/requests");
          notify(notifyMsg);
        }}
      />
    </Toolbar>
  );
};

// TODO: Move to arrays.
const AmountInputs = () => {
  return (
    <>
      <div>
        <SectionTitle label={`Amounts year ${fullYear + 1}`} />
        <NumberInput
          min={1}
          max={99999999999}
          label="January"
          source="year0_january_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="February"
          source="year0_february_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="March"
          source="year0_march_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="April"
          source="year0_april_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="May"
          source="year0_may_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberField
          min={1}
          max={99999999999}
          label="May"
          source="year0_may_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="June"
          source="year0_june_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="July"
          source="year0_july_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="August"
          source="year0_august_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="September"
          source="year0_september_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="October"
          source="year0_october_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="November"
          source="year0_november_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
        <NumberInput
          min={1}
          max={99999999999}
          label="December"
          source="year0_december_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
      </div>
      <div>
        <SectionTitle label={`Amounts year ${fullYear + 2}`} />
        <NumberInput
          min={1}
          max={99999999999}
          label="$"
          source="year1_january_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
      </div>
      <div>
        <SectionTitle label={`Amounts year ${fullYear + 3}`} />
        <NumberInput
          min={1}
          max={99999999999}
          label="$"
          source="year2_january_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
      </div>
      <div>
        <SectionTitle label={`Amounts year ${fullYear + 4}`} />
        <NumberInput
          min={1}
          max={99999999999}
          label="$"
          source="year3_january_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
      </div>
      <div>
        <SectionTitle label={`Amounts year ${fullYear + 5}`} />
        <NumberInput
          min={1}
          max={99999999999}
          label="$"
          source="year4_january_amount"
          variant="outlined"
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
        />
      </div>
    </>
  );
};

const BudgetItemReqApproveTitle = (data) => {
  return <span>Approve request {data ? `"${data.title}"` : ""}</span>;
};

const TextFieldInForm = ({ variant, ...props }) => <TextField {...props} />;
TextFieldInForm.defaultProps = TextField.defaultProps;

const ApproveRequest = ({ requestId, ...props }) => {
  const { data, loaded } = useGetOne("requests", requestId);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  if (!loaded) return <Loading />;

  return (
    <Create
      title={<BudgetItemReqApproveTitle {...data} />}
      {...props}
      basePath="/requests"
      resource="requests/approve"
    >
      <SimpleForm
        initialValues={{
          budgetItemRequestId: requestId,
          departmentId: data.departmentId,
        }}
        variant="outlined"
        validate={validateApproveRequest}
        toolbar={<BudgetItemCreateToolbar notifyMsg="Request Approved" />}
      >
        {isSmall && <TextFieldInForm record={data} source="title" />}
        <TextFieldInForm record={data} source="description" />

        <ReferenceField
          link={false}
          record={data}
          label="Department"
          source="departmentId"
          reference="departments"
          variant="outlined"
        >
          <TextFieldInForm source="name" />
        </ReferenceField>

        <NumberField
          record={data}
          label="Estimated amount"
          locales="en-US"
          source="amount"
          options={{
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol",
          }}
          emptyText="N/A"
        />

        <Separator />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) => (
            <>
              <ReferenceInput
                source="companyId"
                reference="companies"
                variant="outlined"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <Separator />
              {formData.departmentId && formData.companyId && (
                <>
                  <ReferenceInput
                    {...rest}
                    filter={{
                      departmentId: formData.departmentId,
                      companyId: formData.companyId,
                    }}
                    label="Asset Account"
                    source="assetAccountId"
                    reference="assetAccounts"
                    variant="outlined"
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </>
              )}
            </>
          )}
        </FormDataConsumer>

        <TextInput label="Budget Item Name" source="name" variant="outlined" />
        <AmountInputs />
      </SimpleForm>
    </Create>
  );
};
const RejectRequest = ({ requestId, ...props }) => {
  const { data, loaded } = useGetOne("requests", requestId);

  if (!loaded) return <Loading />;

  return (
    <Create
      {...props}
      basePath="/requests"
      resource="requests/reject" /*successMessage={`${data.name} rejected`}*/
    >
      <SimpleForm
        initialValues={{ budgetItemRequestId: requestId }}
        variant="outlined"
        validate={validateRejectRequest}
        toolbar={<BudgetItemCreateToolbar notifyMsg="Request Rejected" />}
      >
        <TextFieldInForm record={data} source="title" addLabel={false} />
        <TextFieldInForm record={data} source="description" />
        <ReferenceField
          link={false}
          record={data}
          label="Department"
          source="departmentId"
          reference="departments"
        >
          <TextFieldInForm source="name" />
        </ReferenceField>

        <TextInput
          source="comment"
          label="Reason"
          multiline
          resettable
          variant="outlined"
        />
      </SimpleForm>
    </Create>
  );
};

/*
const useStyles = makeStyles({
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
});

const years = [{ thisYear: `${fullYear}` }, { yearPlusOne: `${fullYear + 1}` }, { yearPlusTwo: `${fullYear + 2}` }, { yearPlusThree: `${fullYear + 3}` }, { yearPlusFour: `${fullYear + 4}` }];
const amountInputsValues = {
    thisYear: [{ "source": "year0_q1_amount", "label": `${fullYear}-Q1` },],
    yearPlusOne: [],
    yearPlusTwo: [],
    yearPlusThree: [],
    yearPlusFour: [],
};

const toChoices = items => items.map(item => ({ id: item, name: item }));
*/
const SectionTitle = ({ label }) => {
  return <Typography variant="subtitle1">{label}</Typography>;
};

const Separator = () => <Box pt=".1em" />;

export function BudgetItemCreate(props) {
  const flag = useContext(FlagContext);
  //const classes = useStyles();
  const { requestId, status } = parse(props.location.search);

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .checkUser()
      .then(({ data }) => {
        setUser(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <Loading />;
  if (error) return <Error />;
  //if (!user) return null;

  const filterDept = !canI(props.permissions, "budgetItems", "filter")
    ? { filter: { approverId: user.id } }
    : {};

  if (requestId && (status === "approve" || status === "reject")) {
    return status === "approve" ? (
      <ApproveRequest {...props} requestId={requestId} />
    ) : (
      <RejectRequest {...props} requestId={requestId} />
    );
  }

  return flag === false ? (
    <Create {...props}>
      <SimpleForm
        redirect={"list"}
        variant="outlined"
        //initialValues={{ budgetItemRequestId: requestId }}
        //validate={validateApproveRequest}
        //toolbar={<BudgetItemCreateToolbar notifyMsg="Request Approved" />}
      >
        <TextInput label="Description" source="name" variant="outlined" />
        <ReferenceInput
          {...filterDept}
          label="Department"
          source="departmentId"
          reference="departments"
          variant="outlined"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) => (
            <>
              <ReferenceInput
                source="companyId"
                reference="companies"
                variant="outlined"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <Separator />
              {formData.departmentId && formData.companyId && (
                <>
                  <ReferenceInput
                    {...rest}
                    filter={{
                      departmentId: formData.departmentId,
                      companyId: formData.companyId,
                    }}
                    label="Asset Account"
                    source="assetAccountId"
                    reference="assetAccounts"
                    variant="outlined"
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </>
              )}
            </>
          )}
        </FormDataConsumer>
        <AmountInputs />
      </SimpleForm>
    </Create>
  ) : (
    <Toolbar {...props}>
      <CancelButton basePath={"/budgetItems"} />
    </Toolbar>
  );
}

export const BudgetItemShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <RichTextField source="description" />
      <DateField
        label="Created at"
        source="createdAt"
        locale="en-US"
        options={{ month: "2-digit", day: "2-digit", year: "numeric" }}
      />
    </SimpleShowLayout>
  </Show>
);
