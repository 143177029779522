import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      fill="none"
      viewBox="0 0 18 17"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M7.852 3.671h-3.98c-.71 0-1.29.58-1.29 1.29v7.746c0 .71.58 1.29 1.29 1.29h7.745c.71 0 1.29-.58 1.29-1.29V9.695h-1.29v3.012H3.872V4.962h3.98v-1.29zM5.163 9.48h3.154a1.124 1.124 0 01-.465-.909V8.19H5.163v1.29zm2.69-1.937v-1.29h-2.69v1.29h2.69zM1.29 6.253H0v9.035c0 .71.58 1.29 1.29 1.29h9.036v-1.29H1.291V6.253zm3.872 3.872h2.582v1.29H5.163v-1.29z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M17.026 0H10.21a.977.977 0 00-.973.974v6.815c0 .536.438.974.973.974h6.816A.977.977 0 0018 7.79V.974A.977.977 0 0017.026 0zm0 7.79H10.21V.973h6.816v6.815zm-5.842-4.382h.974v3.408h-.974V3.408zm1.948-1.46h.973v4.868h-.973V1.947zm1.947 2.92h.974v1.948h-.974V4.868z"
      ></path>
    </svg>
  );
}

export default Icon;
