export function getCurrentFiscalYear() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Meses en JavaScript son 0-indexados

  let fiscalYear;

  if (currentMonth === 1) {
    fiscalYear = currentDate.getFullYear() - 1;
  } else {
    fiscalYear = currentDate.getFullYear();
  }

  return fiscalYear;
}
