import { fetchUtils } from "react-admin";
import { msalInstance } from "./../index";
import simpleRestProvider from "ra-data-simple-rest";
import fakeRestProvider from "ra-data-fakerest";
import dataFake from "./dataFake";
import isEqual from "lodash/isEqual";

const dataProviderFake = fakeRestProvider(dataFake, true);

const apiUrl = process.env.REACT_APP_RESTFULAPI_URL
  ? process.env.REACT_APP_RESTFULAPI_URL
  : "https://budgets-api.azurewebsites.net/api";

const httpClient = async (url, options = {}) => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }
  const request = {
    scopes: [], //"User.Read"
    account: account,
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  const token = authResult.idToken;
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set("Authorization", `Bearer ${token}`);
  //options.headers.set("Access-Control-Allow-Origin", '*');
  // the fectch
  return fetchUtils.fetchJson(url, options);
};

const httpClientWithoutLogin = async (url, options = {}) => {
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = process.env.REACT_APP_FAKE
  ? dataProviderFake
  : simpleRestProvider(apiUrl, httpClient, "x-total-count");

const getDifference = (a, b) =>
  Object.entries(a).reduce(
    (ac, [k, v]) => (b[k] && !isEqual(b[k], v) ? ((ac[k] = b[k]), ac) : ac),
    {}
  );

const myDataProvider = {
  ...dataProvider,
  update: (resource, params) => {
    const toUpdate = getDifference(params.previousData, params.data);
    const data = Object.keys(toUpdate).map((key) => ({
      op: "replace",
      path: key,
      value: toUpdate[key],
    }));

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(data),
    }).then((res) => ({ data: params.data }));
  },
  modifyFreeze: async (param) => {
    const url = `${apiUrl}/users/modifyFreeze`;
    const { json } = await httpClient(url, {
      method: "PATCH",
      body: JSON.stringify({
        op: "replace",
        path: "/active",
        value: param === true ? false : true,
      }),
    });
    return { data: json };
  },

  
  downloadReportCompanies: async (filters, sort, currentPage, perPage) => {
       let response = await httpClient(
         `${apiUrl}/reports/companies/export?filter=${JSON.stringify(filters)}&sort=["${
           sort.field
         }","${sort.order}"]&range=[${(currentPage - 1) * perPage},${
           (currentPage - 1) * perPage + perPage - 1
         }]`
       );
       let data = response.body.substring(1).slice(0, -1); // Remove "" from beggining and end
       var fileUrl = generateFileUrl(data);
       var fileName = `reports_${new Date(Date.now()).toISOString()}.xlsx`;
       downloadFile(fileUrl, fileName);
     },

     downloadReportByCompany: async (companyId) => {
      let response = await httpClient(
        `${apiUrl}/reports/companies/export/${companyId}`
      );
      let data = response.body.substring(1).slice(0, -1); // Remove "" from beggining and end
      var fileUrl = generateFileUrl(data);
      var fileName = `reports_${new Date(Date.now()).toISOString()}.xlsx`;
      downloadFile(fileUrl, fileName);
    },

    downloadReportGLAccount: async (filters, sort, currentPage, perPage) => {
      let response = await httpClient(
        `${apiUrl}/reports/assets/export?filter=${JSON.stringify(filters)}&sort=["${
          sort.field
        }","${sort.order}"]&range=[${(currentPage - 1) * perPage},${
          (currentPage - 1) * perPage + perPage - 1
        }]`
      );
      let data = response.body.substring(1).slice(0, -1); // Remove "" from beggining and end
      var fileUrl = generateFileUrl(data);
      var fileName = `reports_${new Date(Date.now()).toISOString()}.xlsx`;
      downloadFile(fileUrl, fileName);
    },

    downloadReportByGLAccount: async (assetId) => {
      let response = await httpClient(
        `${apiUrl}/reports/assets/export/${assetId}`
      );
      let data = response.body.substring(1).slice(0, -1); // Remove "" from beggining and end
      var fileUrl = generateFileUrl(data);
      var fileName = `reports_${new Date(Date.now()).toISOString()}.xlsx`;
      downloadFile(fileUrl, fileName);
    },

  //   downloadReportFull: async (filters, sort, currentPage, perPage) => {
  //     let response = await httpClient(
  //       `${apiUrl}/reports/export?filter=${JSON.stringify(filters)}&sort=["${
  //         sort.field
  //       }","${sort.order}"]&range=[${(currentPage - 1) * perPage},${
  //         (currentPage - 1) * perPage + perPage - 1
  //       }]`
  //     );
  //     let data = response.body.substring(1).slice(0, -1); // Remove "" from beggining and end
  //     var fileUrl = generateFileUrl(data);
  //     var fileName = `reports_${new Date(Date.now()).toISOString()}.xlsx`;
  //     downloadFile(fileUrl, fileName);
  //   },

  checkUser: async () => {
    const url = `${apiUrl}/users/checkUser`;
    const { json } = await httpClient(url);
    return { data: json };
  },
  // getCompanyReport: async (companyId) => {
  //   const url = `${apiUrl}/companiesReports/${companyId}`;
  //   const { json } = await httpClient(url);
  //   return { data: json };
  // },
  rollbackReject: async (requestId) => {
    return await httpClient(`${apiUrl}/requests/${requestId}/rollbackReject`);
  },

  getFreezeStatus: async () => {
    const url = `${apiUrl}/users/checkFreeze`;
    const { json } = await httpClientWithoutLogin(url);
    return { data: json };
  },
  downloadBudgetItemsGroupedReport: async (filters, sort) => {
    let response = await httpClient(
      `${apiUrl}/budgetItemsGrouped/export?filter=${JSON.stringify(
        filters
      )}&sort=["${sort.field}","${sort.order}"]`
    );
    let data = response.body.substring(1).slice(0, -1); // Remove "" from beggining and end
    var fileUrl = generateFileUrl(data);
    var fileName = `budget_items_grouped_${new Date(
      Date.now()
    ).toISOString()}.xlsx`;
    downloadFile(fileUrl, fileName);
  },
  downloadBudgetItemsGroupedSummaryReport: async (filters, sort) => {
    let response = await httpClient(
      `${apiUrl}/budgetItemsGrouped/export/summary?filter=${JSON.stringify(
        filters
      )}&sort=["${sort.field}","${sort.order}"]`
    );
    let data = response.body.substring(1).slice(0, -1); // Remove "" from beggining and end
    var fileUrl = generateFileUrl(data);
    var fileName = `budget_items_grouped_summary_${new Date(
      Date.now()
    ).toISOString()}.xlsx`;
    downloadFile(fileUrl, fileName);
  },
};

const downloadFile = (url, fileName) => {
  var download = document.createElement("a");
  download.href = url;
  download.download = fileName;
  document.body.appendChild(download);
  download.click();
  document.body.removeChild(download);
};

const generateFileUrl = (data) => {
  return `data:application/vnd.ms-excel;base64,${encodeURIComponent(data)}`;
};

export default myDataProvider;
