import * as React from 'react';
import { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  theme => ({
      menuItem: {
          color: theme.palette.text.secondary,
      },
      icon: { minWidth: theme.spacing(5) },
  })
);

const LogoutButton = forwardRef((props, ref) => {
  const classes = useStyles(props);
  const logout = useLogout();
  const handleClick = () => logout();
  return (
    <MenuItem
      onClick={handleClick}
      className={classes.menuItem}
      ref={ref}
    >
      <ListItemIcon className={classes.icon}>
        <ExitToAppIcon />
      </ListItemIcon>
      Logout
    </MenuItem>
  );
});

export default LogoutButton;