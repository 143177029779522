import React from "react";
import {
  Create,
  Datagrid,
  DeleteWithUndoButton,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

const filters = [<TextInput source="name" alwaysOn />];

export const CompaniesList = (props) => {
  return (
    <List {...props} filters={filters}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <EditButton />
        <DeleteWithUndoButton />
      </Datagrid>
    </List>
  );
};

export const CompanyCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="description" />
      </SimpleForm>
    </Create>
  );
};

export const CompanyEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="description" />
      </SimpleForm>
    </Edit>
  );
};
