import React from "react";
import PropTypes from "prop-types";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getCurrentFiscalYear } from "../../yearUtils";
//import classnames from 'classnames';
//import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
//import { TextField, Datagrid, useList, ListContextProvider } from 'react-admin';

const useStyles = makeStyles({
  container: { marginBottom: "1rem" },
  rightAlignedCell: { textAlign: "right" },
  boldCell: { fontWeight: "bold" },
  table: { minWidth: 650 },
  head: { backgroundColor: "#eef0fb" },
});

const fullYear = getCurrentFiscalYear() + 1;

const BudgetThisYearLabel = () => {
  return `Total ${fullYear}`;
};

const BudgetNext4YearsLabel = () => {
  return `Total ${fullYear + 1}-${fullYear + 4}`;
};

const TotalThisYearPlusOneLabel = () => {
  return `Total ${fullYear + 1}`;
};

const TotalThisYearPlusTwoLabel = () => {
  return `Total ${fullYear + 2}`;
};

const TotalThisYearPlusThreeLabel = () => {
  return `Total ${fullYear + 3}`;
};

const TotalThisYearPlusFourLabel = () => {
  return `Total ${fullYear + 4}`;
};
/**
totalNextFourYears: 0
totalThisYear: 3500
totalThisYearPlusFour: 0
totalThisYearPlusOne: 0
totalThisYearPlusThree: 0
totalThisYearPlusTwo: 0
totalThisYearQ1: 1000
totalThisYearQ2: 0
totalThisYearQ3: 2500
totalThisYearQ4: 0 
 */

const Totals = (props) => {
  const { data } = props;

  //console.log(`data`, data);
  const classes = useStyles(props);

  //const listContext = useList({data});
  // TODO: convert the cells to Arrays.
  return (
    <div className={classes.container}>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="report">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell align="left">
                <BudgetThisYearLabel />
              </TableCell>
              <TableCell align="left">January</TableCell>
              <TableCell align="left">February</TableCell>
              <TableCell align="left">March</TableCell>
              <TableCell align="left">April</TableCell>
              <TableCell align="left">May</TableCell>
              <TableCell align="left">June</TableCell>
              <TableCell align="left">July</TableCell>
              <TableCell align="left">August</TableCell>
              <TableCell align="left">September</TableCell>
              <TableCell align="left">October</TableCell>
              <TableCell align="left">November</TableCell>
              <TableCell align="left">December</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data.totalThisYear.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearJanuary?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearFebruary?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearMarch?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearApril?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearMay?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearJune?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearJuly?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearAugust?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearSeptember?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearOctober?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearNovember?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearDecember?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer
        component={Paper}
        style={{ marginTop: "1rem", maxWidth: "60%" }}
      >
        <Table className={classes.table} size="small" aria-label="report">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell align="left">
                <TotalThisYearPlusOneLabel />
              </TableCell>
              <TableCell align="left">
                <TotalThisYearPlusTwoLabel />
              </TableCell>
              <TableCell align="left">
                <TotalThisYearPlusThreeLabel />
              </TableCell>
              <TableCell align="left">
                <TotalThisYearPlusFourLabel />
              </TableCell>
              <TableCell align="left">
                <BudgetNext4YearsLabel />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalNextYear?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearPlusOne?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearPlusTwo?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalThisYearPlusThree?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography component="span" variant="body2">
                  $&nbsp;
                  {data?.totalNextFourYears?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
Totals.defaultProps = {
  data: undefined,
};
Totals.propTypes = {
  data: PropTypes.object,
};

export default Totals;
