const ROLES = {
  ADMIN: "admin",
  APPROVER: "approver",
  USER: "user",
  REQUESTER: "requester",
};

const ALLOW = "*";

// * todos tienen permiso
// [] nadie tiene permiso
// [ xx ] xx tiene permiso

const permissionsByResource = {
  requests: {
    list: ALLOW,
    edit: [ROLES.ADMIN, ROLES.APPROVER, ROLES.REQUESTER],
    create: [ROLES.ADMIN, ROLES.APPROVER, ROLES.REQUESTER],
    show: ALLOW,
    delete: [ROLES.ADMIN, ROLES.APPROVER, ROLES.REQUESTER],
    approve: [ROLES.ADMIN, ROLES.APPROVER],
    listAllRequests: [ROLES.ADMIN, ROLES.APPROVER, ROLES.USER],
  },
  assetAccounts: {
    list: [ROLES.ADMIN, ROLES.APPROVER],
    edit: [ROLES.ADMIN],
    create: [ROLES.ADMIN],
    show: [ROLES.ADMIN, ROLES.APPROVER],
    delete: [ROLES.ADMIN],
  },
  budgetItems: {
    list: ALLOW,
    edit: [ROLES.ADMIN, ROLES.APPROVER],
    create: [ROLES.ADMIN, ROLES.APPROVER],
    show: ALLOW,
    delete: [ROLES.ADMIN, ROLES.APPROVER],
    filter: [ROLES.ADMIN],
  },
  departments: {
    list: [ROLES.ADMIN],
    edit: [ROLES.ADMIN],
    create: [ROLES.ADMIN],
    show: [ROLES.ADMIN],
    delete: [ROLES.ADMIN],
  },
  reports: {
    list: [ROLES.ADMIN, ROLES.APPROVER],
    edit: [ROLES.ADMIN],
    create: [ROLES.ADMIN],
    show: [ROLES.ADMIN, ROLES.APPROVER],
    delete: [ROLES.ADMIN],
  },
  users: {
    list: [ROLES.ADMIN],
  },
  downloadReports: [ROLES.ADMIN],
  budgetItemsGrouped: {
    list: [ROLES.ADMIN, ROLES.APPROVER],
  },
  budgetItemsHistorical: {
    list: [ROLES.ADMIN, ROLES.APPROVER],
  },
  companies: {
    list: [ROLES.ADMIN],
    edit: [ROLES.ADMIN],
    create: [ROLES.ADMIN],
    delete: [ROLES.ADMIN],
  },
  glReports: {
    list: [ROLES.ADMIN, ROLES.APPROVER],
    edit: [ROLES.ADMIN],
    create: [ROLES.ADMIN],
    show: [ROLES.ADMIN, ROLES.APPROVER],
    delete: [ROLES.ADMIN],
  },
  companiesReports: {
    list: [ROLES.ADMIN, ROLES.APPROVER],
    edit: [ROLES.ADMIN],
    create: [ROLES.ADMIN],
    show: [ROLES.ADMIN, ROLES.APPROVER],
    delete: [ROLES.ADMIN],
  },
};

// roles es un array de roles
const canI = (roles, resource, action = null) => {
  if (roles && Array.isArray(roles)) {
    return roles.some((currentRole) => {
      const actionPermissions = action
        ? permissionsByResource[resource][action]
        : permissionsByResource[resource];
      if (actionPermissions === ALLOW) return true;
      else return actionPermissions.includes(currentRole);
    });
  } else return false;
};

export { canI, permissionsByResource };
