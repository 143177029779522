import Cancel from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const CancelButton = ({ basePath }) => {
  const history = useHistory();

  const useStyles = makeStyles({
    cancelButton: {
      backgroundColor: '#d24545',
      color: 'white',
      padding: '6px 16px',
      fontSize: '0.875rem',
      marginRight: '.5rem',
      borderRadius: '5px',
      borderWidth: '0',
      '&:hover': {
        backgroundColor: '#c92121',
        "cursor": "pointer"
      },
      display: 'flex',
      alignItems: 'center',
      lineHeight: '1.75'
    }
  });

  const classes = useStyles();

  return (
    <button onClick={() => history.push(basePath)} className={classes.cancelButton} >
      <Cancel fontSize={'small'} />
      <span style={{'marginLeft': '.4rem'}}>Cancel</span>
    </button>
  )
}

export default CancelButton