import * as React from 'react';
import {
    Show,
    SimpleShowLayout,
    RichTextField,
    DateField,
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    SaveButton,
    Toolbar,
    FunctionField,
    TopToolbar,
    FilterButton,
    usePermissions,
    CreateButton,
    EditButton,
    useNotify,
    useRefresh,
    useRedirect
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import SearchInput from '../../components/SearchInput';
//import ViewMore from '../../components/ViewMore';
//import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';

import { canI } from '../../config/rolePermissions';
import CancelButton from '../../components/CancelButton';
//import EditButtonIcon from '../../components/EditButtonIcon';


const departmentsFilters = [
    <SearchInput key="search-input-departament" source="q" label="Search" resettable alwaysOn variant="outlined" />
];

const validateDepartmentCreation = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'The name is required';
    }
    if (!values.description) {
        errors.description = 'The description is required';
    }
    if (!values.approverId) {
        errors.approverId = 'The Approver is required';
    }
    return errors
};

const useActionStyles = makeStyles(theme => ({
    createButton: {
        marginLeft: theme.spacing(2),
    },
}));

const DepartmentListActions = () => {
    const { loading, permissions } = usePermissions();
    const classes = useActionStyles();
    return (
        <TopToolbar>
            <FilterButton />
            {!loading && canI(permissions, "departments", 'create') && <CreateButton className={classes.createButton} icon={<AddBoxIcon />} variant="contained" />}

        </TopToolbar>
    );
};

const renderAssetAccounts = (record) => {
    let text = record.assetAccounts && record.assetAccounts.map(asset => asset.name);

    //return <ViewMore limit={1000} >{text.join(", ")}</ViewMore>
    return text ? text.map((elem, i) => <span key={i} style={{ display: "block", whiteSpace: "nowrap" }}>{elem}</span>) : '';
}

export const DepartmentList = props => (
    <List {...props} filters={departmentsFilters} actions={<DepartmentListActions />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="name" />
            <TextField source="description" />
            <TextField label="Approver User" source="approverName" />
            <FunctionField label="GL account" render={renderAssetAccounts} />
            {props.permissions && canI(props.permissions, "departments", 'edit') && <EditButton label="" title="Edit" size="small" />}
        </Datagrid>
    </List>
);

const DepartmentEditActions = (props) => (
    <Toolbar {...props} >
        <CancelButton basePath={'/departments'} />
        <SaveButton disabled={props.pristine || props.validating} />
    </Toolbar>
);

const DepartmentTitle = ({ record }) => {
    return <span>Department {record ? `"${record.description}"` : ''}</span>;
};

export const DepartmentEdit = props => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify("Department updated", "info", null, true);
        redirect('/departments');
        refresh();
    }

    return (
        <Edit title={<DepartmentTitle />} {...props} actions={false} onSuccess={onSuccess}>
            <SimpleForm
                variant="outlined"
                warnWhenUnsavedChanges
                toolbar={<DepartmentEditActions />}
                validate={validateDepartmentCreation}
                redirect="list">
                <TextInput source="name" variant="outlined" />
                <TextInput multiline source="description" variant="outlined" />
                <ReferenceInput label="Approver" source="approverId" reference="users" variant="outlined">
                    <SelectInput source="name" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
}

export function DepartmentCreate(props) {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify("Department created");
        redirect('/departments');
        refresh();
    }

    return (
        <Create {...props} onSuccess={onSuccess}>
            <SimpleForm
                variant="outlined"
                validate={validateDepartmentCreation}
                redirect="list"
                toolbar={<DepartmentEditActions />}
            >
                <TextInput source="name" variant="outlined" />
                <TextInput multiline source="description" variant="outlined" />
                <ReferenceInput label="Approver" source="approverId" reference="users" variant="outlined">
                    <SelectInput source="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
}

export const DepartmentShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <RichTextField source="description" />
            <DateField label="Created at" source="createdAt" locale='en-US' options={{ month: '2-digit', day: '2-digit', year: 'numeric' }} />
        </SimpleShowLayout>
    </Show>
);