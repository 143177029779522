import { ListButton } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const ReturnButton = ({basePath, label}) => {
  return (
    <ListButton basePath={basePath} label={label} icon={<ChevronLeft />} />
  )
}

export default ReturnButton
