import { defaultTheme } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';
import createPalette from "@material-ui/core/styles/createPalette";
import defaultMuiTheme from "@material-ui/core/styles/defaultTheme";
import merge from 'lodash/merge';
import tinycolor from "tinycolor2";
import { DRAWER_WIDTH, CLOSED_DRAWER_WIDTH } from 'ra-ui-materialui/lib/layout/Sidebar.js';
const primary = "#003CA5";
const secondary = "#1355A2";
const warning = "#D50000";
const success = "#FF5722";
const info = "#37474F";

const lightenRate = 7.5;
const darkenRate = 15;

const palette = createPalette(
    merge({}, defaultTheme.palette, {
        primary: {
            main: primary,
            light: tinycolor(primary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(primary)
                .darken(darkenRate)
                .toHexString(),
        },
        secondary: {
            main: secondary,
            light: tinycolor(secondary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(secondary)
                .darken(darkenRate)
                .toHexString(),
            contrastText: "#FFFFFF",
        },
        warning: {
            main: warning,
            light: tinycolor(warning)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(warning)
                .darken(darkenRate)
                .toHexString(),
        },
        success: {
            main: success,
            light: tinycolor(success)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(success)
                .darken(darkenRate)
                .toHexString(),
        },
        info: {
            main: info,
            light: tinycolor(info)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(info)
                .darken(darkenRate)
                .toHexString(),
        },
        text: {
            primary: "#4A4A4A",
            secondary: "#6E6E6E",
            hint: "#f44336",
        },
        background: {
            default: "#f8f8f8",
            light: "#FFFFFF",
        },
    })
);

const typography = {
    fontFamilySecondary: "'Poppins', sans-serif",
    fontFamily: '"Comic Neue", cursive',
    fontSize: 16,
    fontStyle: "normal",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    color: palette.text.primary,
};


const rawTheme = createTheme(merge({}, defaultTheme, {
    palette,

    overrides: {
        MuiButton: {
            root: {
                borderRadius: defaultMuiTheme.spacing(.5),
                textTransform: "none",
                minWidth: 32
            },
            sizeLarge: {
                fontSize: "1.25rem",
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: defaultMuiTheme.spacing(.5),
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: "#4A4A4A1A",
            },
        },
        MuiSelect: {
            icon: {
                color: palette.secondary.main,
            },
        },
        /*
        MuiListItem: {
          root: {
            "&$selected": {
              backgroundColor: "#F3F5FF !important",
              "&:focus": {
                backgroundColor: "#F3F5FF",
              },
            },
          },
          button: {
            "&:hover, &:focus": {
              backgroundColor: "#F3F5FF",
            },
          },
        },
        MuiTouchRipple: {
          child: {
              backgroundColor: "white",
          },
        },
    
        MuiTableCell: {
          root: {
            borderBottom: "1px solid rgba(224, 224, 224, .5)",
          },
          head: {
            fontSize: "1rem",
          },
          body: {
            fontSize: "0.95rem",
          },
        },
        */
        MuiMenu: {
            paper: {
                borderRadius: defaultMuiTheme.spacing(.5)
            }
        },
        MuiTableCell: {
            sizeSmall: {
                padding: "6px 12px", //pasar a valores con "defaultMuiTheme.spacing(1)"
                whiteSpace: 'nowrap',
            },
            head: {
                /*
                textTransform: "uppercase",
                */
                fontWeight: 500,
            },
        },
        MuiFab: {
            root: {
                fontWeight: "bold",
            },
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: "#eef0fb",
                color: palette.text.primary,
            },
        },

        // React-Admin
        RaDatagrid: {
            headerCell: {
                backgroundColor: "#EDEDED",
                color: "#575757"
            }
        },
        RaSidebar: {
            root: {
                width: props => props.open ? `${DRAWER_WIDTH}px` : `${CLOSED_DRAWER_WIDTH}px`,
                height: `calc(100vh -  ${defaultMuiTheme.spacing(11)}px)`,
            },
            fixed: {
                height: `calc(100vh - ${defaultMuiTheme.spacing(11)}px)`,
            },
            drawerPaper: {
                backgroundColor: palette.secondary.main,
                color: palette.common.white,
                boxShadow: "1px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",
                position: "fixed",
                zIndex: "1200",
            },
        },
        RaMenuItemLink: {
            root: {
                color: palette.common.white,
                marginBottom: defaultMuiTheme.spacing(2),
                [defaultMuiTheme.breakpoints.only('xs')]: {
                    color: palette.primary.main,
                    marginBottom: defaultMuiTheme.spacing(2),
                }
            },
            active: {
                borderLeftStyle: "none",
                borderRightColor: palette.common.white,
                borderRightWidth: defaultMuiTheme.spacing(0.5),
                borderRightStyle: "solid",
                backgroundColor: palette.action.selected,
                color: palette.common.white,
                fontWeight: 500,
                [defaultMuiTheme.breakpoints.only('xs')]: {
                    color: palette.primary.main,
                    borderRightColor: palette.secondary.main,
                }
            },
            icon: {
                color: "inherit",
            },
        },
        RaLayout: {
            content: {
                height: "auto",
                backgroundColor: palette.background.default,
                paddingTop: defaultMuiTheme.spacing(0),
                paddingRight: defaultMuiTheme.spacing(0),
                paddingBottom: defaultMuiTheme.spacing(0),
                paddingLeft: defaultMuiTheme.spacing(0),
                display: "flex",
                flexDirection: "column",
                [defaultMuiTheme.breakpoints.up("xs")]: {
                    paddingTop: defaultMuiTheme.spacing(0),
                    paddingRight: defaultMuiTheme.spacing(3),
                    paddingBottom: defaultMuiTheme.spacing(3),
                    paddingLeft: defaultMuiTheme.spacing(3),
                },
                [defaultMuiTheme.breakpoints.down('xs')]: {
                    paddingTop: defaultMuiTheme.spacing(0),
                    paddingRight: defaultMuiTheme.spacing(0),
                    paddingBottom: defaultMuiTheme.spacing(0),
                    paddingLeft: defaultMuiTheme.spacing(0),
                }
                /*s
                "& > div, & > h2": {
                  paddingTop: defaultMuiTheme.spacing(0),
                  paddingRight: defaultMuiTheme.spacing(0),
                  paddingBottom: defaultMuiTheme.spacing(0),
                  paddingLeft: defaultMuiTheme.spacing(0),
                  [defaultMuiTheme.breakpoints.up("xs")]: {
                    paddingTop: defaultMuiTheme.spacing(1),
                    paddingRight: defaultMuiTheme.spacing(1),
                    paddingBottom: defaultMuiTheme.spacing(1),
                    paddingLeft: defaultMuiTheme.spacing(1),
                  },
                },
                */
            },
            appFrame: {
                [defaultMuiTheme.breakpoints.up('xs')]: {
                    marginTop: defaultMuiTheme.spacing(11),
                },
                [defaultMuiTheme.breakpoints.down('xs')]: {
                    marginTop: defaultMuiTheme.spacing(8),
                },
            },
        },
        RaAppBar: {
            toolbar: {
                MuiIconButton: {
                    root: {
                        fontSize: "1.25rem",
                    },
                },
            },
        },
        RaTabbedShowLayout: {
            content: {
                marginTop: defaultMuiTheme.spacing(4),
                backgroundColor: palette.common.white,
                boxShadow: defaultMuiTheme.shadows[3],
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        RaShow: {
            main: {
                marginTop: defaultMuiTheme.spacing(2),
            },
            noActions: {
                marginTop: defaultMuiTheme.spacing(2),
            },

        },
        RaFilter: {
            form: {},
            button: {
                borderStyle: "solid",
                borderWidth: "2px",
                borderColor: palette.grey[300],
                textTransform: "uppercase",
                fontWeight: typography.fontWeightBold,
                color: palette.primary.main,
                paddingLeft: defaultMuiTheme.spacing(2),
                paddingRight: defaultMuiTheme.spacing(2),
                paddingTop: defaultMuiTheme.spacing(1),
                paddingBottom: defaultMuiTheme.spacing(1),
            },
        },
        RaListToolbar: {
            /*
            toolbar: {
              paddingBottom: defaultMuiTheme.spacing(1),
              borderBottomStyle: "solid",
              borderBottomWidth: "1px",
              borderBottomColor: palette.grey[300],
              marginBottom: defaultMuiTheme.spacing(2),
            },
            */
            actions: {
                marginRight: "0",
            },
        },

        /*
        RaDeleteWithUndoButton: {
          deleteButton: {
            [defaultMuiTheme.breakpoints.up('xs')]: {
              flex: '0 0 auto',
              padding: '12px',
              display: 'inline-flex',
              borderRadius: '50%',
              minWidth: 'auto'
            }
          }
        },
        */
        RaLabeled: {
            label: {
                fontSize: '1.4rem',
                color: "#575757",
                fontWeight: 500
            }
        },
        /*
        MuiTypography: {
          body2: {
            fontSize: '1rem',
          }
        },
        */
        RaAutocompleteArrayInput: {
            inputRoot: {
                width: '256px'
            }
        }
    },
}));

export default rawTheme;