import React, { Children, cloneElement, isValidElement, useState } from "react";
import { useTranslate, useGetIdentity } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Tooltip, IconButton, Menu, Button, Avatar } from "@material-ui/core";

const useStylesMyMenu = makeStyles(
    (theme) => ({
        user: {},
        userButton: {
            textTransform: "uppercase",
        },
        avatar: {
            width: theme.spacing(5),
            height: theme.spacing(5),
            color: "#ffffff",
            backgroundColor: "#0b5394",
        },
    }),
    { name: "RaUserMenu" }
);

const AnchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
};

const TransformOrigin = {
    vertical: "top",
    horizontal: "right",
};

const CustomUserMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const translate = useTranslate();
    const { loaded, identity } = useGetIdentity();
    const classes = useStylesMyMenu(props);

    const { children, label = "ra.auth.user_menu", logout } = props;

    if (!logout && !children) return null;
    const open = Boolean(anchorEl);

    const handleMenu = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div className={classes.user}>
            {loaded && identity?.fullName ? (
                <>
                    <Button
                        aria-label={label && translate(label, { _: label })}
                        className={classes.userButton}
                        color="inherit"
                        startIcon={
                            identity.avatar ? (
                                <Avatar
                                    className={classes.avatar}
                                    src={identity.avatar}
                                    alt={identity.fullName}
                                />
                            ) : (
                                <Avatar className={classes.avatar}>
                                    {identity.fullName.charAt(0) +
                                        identity.fullName
                                            .split(" ")[1]
                                            .toString()
                                            .charAt(0)}
                                </Avatar>
                            )
                        }
                        onClick={handleMenu}
                    >
                        {identity.fullName}
                    </Button>
                    <IconButton
                        aria-label={label && translate(label, { _: label })}
                        aria-owns={open ? "menu-appbar" : null}
                        aria-haspopup={true}
                        color="inherit"
                        onClick={handleMenu}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </>
            ) : (
                <Tooltip title={label && translate(label, { _: label })}>
                    <IconButton
                        aria-label={label && translate(label, { _: label })}
                        aria-owns={open ? "menu-appbar" : null}
                        aria-haspopup={true}
                        color="inherit"
                        onClick={handleMenu}
                    >
                        <Avatar className={classes.avatar} />
                    </IconButton>
                </Tooltip>
            )}
            <Menu
                id="menu-appbar"
                disableScrollLock
                anchorEl={anchorEl}
                anchorOrigin={AnchorOrigin}
                transformOrigin={TransformOrigin}
                // Make sure the menu is display under the button and not over the appbar
                // See https://material-ui.com/components/menus/#customized-menus
                getContentAnchorEl={null}
                open={open}
                onClose={handleClose}
            >
                {Children.map(children, (menuItem) =>
                    isValidElement(menuItem)
                        ? cloneElement <
                          any >
                          (menuItem,
                          {
                              onClick: handleClose,
                          })
                        : null
                )}
                {logout}
            </Menu>
        </div>
    );
};

export default CustomUserMenu;
