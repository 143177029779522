import * as React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { toggleSidebar, useTranslate } from 'ra-core';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(
    theme => ({
        menuButtonIconClosed: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(0deg)',
        },
        menuButtonIconOpen: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(180deg)',
        },
    }),
    { name: 'RaSidebarToggleButton' }
);

const SidebarToggleButton = (props) => {
    const translate = useTranslate();
    const classes = useStyles(props);
    const { classNameOpen, classNameClosed } = props;
    const open = useSelector(
        state => state.admin.ui.sidebarOpen
    );
    const dispatch = useDispatch();

    const toggle = () => dispatch(toggleSidebar());
    return (
        <Tooltip
            title={translate(
                open ? 'ra.action.close_menu' : 'ra.action.open_menu',
                {
                    _: 'Open/Close menu',
                }
            )}
            enterDelay={250}
        >
            <IconButton
                color="inherit"
                onClick={() => toggle()}
                className={open
                    ? classNameOpen
                    : classNameClosed}
            >
                {open ? <MenuOpenIcon classes={{ root: classes.menuButtonIconClosed }} /> : <MenuIcon classes={{ root: classes.menuButtonIconOpen }} />}
            </IconButton>
        </Tooltip>
    );
};

export default SidebarToggleButton;