import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AddBoxIcon from "@material-ui/icons/AddBox";
import React from "react";
import {
    AutocompleteArrayInput,
    Create,
    CreateButton,
    Datagrid,
    DateInput,
    Edit,
    EditButton,
    FilterButton,
    List,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    useNotify,
    usePermissions,
    useRedirect,
    useRefresh,
} from "react-admin";
import CancelButton from "../../components/CancelButton";
import Empty from "../../components/Empty";
import SearchInput from "../../components/SearchInput";
import { canI } from "../../config/rolePermissions";

const STATUS_CHOICES = [
    { id: 0, name: "INACTIVE" },
    { id: 1, name: "ACTIVE" },
];

const assetAccountsFilters = [
    <SearchInput
        key="search-input-asetAccounts"
        source="q"
        label="Search"
        resettable
        alwaysOn
        variant="outlined"
    />,
    <TextInput label="GL account" source="name" variant="outlined" />,
    <DateInput
        label="Created date from"
        source="createdAt_gte"
        variant="outlined"
    />,
    <DateInput
        label="Created date to"
        source="createdAt_lt"
        variant="outlined"
    />,
    <ReferenceInput
        label="Company"
        source="companyId"
        reference="companies"
        variant="outlined"
    >
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <SelectInput variant="outlined" source="status" choices={STATUS_CHOICES} />,
    <ReferenceInput
        label="Department"
        source="departmentId"
        reference="departments"
        variant="outlined"
    >
        <SelectInput optionText="name" />
    </ReferenceInput>,
];

const useActionStyles = makeStyles((theme) => ({
    createButton: {
        marginLeft: theme.spacing(2),
    },
}));
const AssetAccountsListActions = () => {
    const classes = useActionStyles();
    const { loading, permissions } = usePermissions();
    return (
        <TopToolbar>
            <FilterButton />
            {!loading && canI(permissions, "assetAccounts", "create") && (
                <CreateButton
                    className={classes.createButton}
                    icon={<AddBoxIcon />}
                    variant="contained"
                />
            )}
        </TopToolbar>
    );
};

const CustomFieldDepartment = (props) => {
    if (props.data && Object.keys(props.data).length) {
        let text = Object.keys(props.data).map((id) => props.data[id].name);
        return (
            <Typography component="span" variant="body2">
                {text.join(", ")}
            </Typography>
        );
    }
    return "";
};

const useListStyles = makeStyles((theme) => ({
    root: {
        whiteSpace: "nowrap",
    },
}));

export const AssetAccountsList = (props) => {
    const classes = useListStyles();
    return (
        <List
            empty={<Empty msg="No Asset Accounts yet." />}
            {...props}
            filters={assetAccountsFilters}
            actions={<AssetAccountsListActions />}
            bulkActionButtons={false}
        >
            <Datagrid
                rowClick={(id) =>
                    `/budgetItems?filter=${JSON.stringify({
                        "assetAccount--id": id,
                    })}`
                }
            >
                <TextField
                    label="GL account"
                    source="name"
                    className={classes.root}
                />
                <TextField source="description" />
                <TextField label="Company" source="company.name" />
                <ReferenceArrayField
                    link={false}
                    label="Department"
                    source="departmentIds"
                    reference="departments"
                >
                    <CustomFieldDepartment />
                </ReferenceArrayField>
                {props.permissions &&
                    canI(props.permissions, "assetAccounts", "edit") && (
                        <EditButton label="" title="Edit" size="small" />
                    )}
            </Datagrid>
        </List>
    );
};

const AssetAccountsTitle = ({ record }) => {
    return <span>Asset Account {record ? `"${record.name}"` : ""}</span>;
};
const validateAssetAccounts = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = "GL account is required";
    }
    if (!values.companyId) {
        errors.companyId = "Company is required";
    }
    return errors;
};

const ToolbarEditActions = (props) => (
    <Toolbar {...props}>
        <CancelButton basePath={"/assetaccounts"} />
        <SaveButton disabled={props.pristine || props.validating} />
    </Toolbar>
);

export const AssetAccountsEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify("Asset Account updated", "info", null, true);
        redirect("/assetaccounts");
        refresh();
    };

    return (
        <Edit
            actions={false}
            title={<AssetAccountsTitle />}
            {...props}
            onSuccess={onSuccess}
        >
            <SimpleForm
                variant="standard"
                validate={validateAssetAccounts}
                redirect="list"
                toolbar={<ToolbarEditActions />}
            >
                <TextInput
                    label="GL account"
                    source="name"
                    variant="outlined"
                />
                <TextInput source="description" variant="outlined" />
                <ReferenceArrayInput
                    label="Department"
                    source="departmentIds"
                    reference="departments"
                    variant="outlined"
                >
                    <AutocompleteArrayInput optionText="name" />
                </ReferenceArrayInput>
                <ReferenceInput
                    label="Company"
                    source="companyId"
                    reference="companies"
                    variant="outlined"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export const AssetAccountsCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify("Asset Account created");
        redirect("/assetaccounts");
        refresh();
    };

    return (
        <Create {...props} onSuccess={onSuccess}>
            <SimpleForm
                variant="standard"
                validate={validateAssetAccounts}
                redirect="list"
                toolbar={<ToolbarEditActions />}
            >
                <TextInput
                    label="GL account"
                    source="name"
                    variant="outlined"
                />
                <TextInput source="description" variant="outlined" />
                <ReferenceArrayInput
                    label="Department"
                    source="departmentId"
                    reference="departments"
                    variant="outlined"
                >
                    <AutocompleteArrayInput optionText="name" />
                </ReferenceArrayInput>
                <ReferenceInput
                    label="Company"
                    source="companyId"
                    reference="companies"
                    variant="outlined"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};
