import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      fill="none"
      viewBox="0 0 18 17"
    >
      <path
        fill="#fff"
        d="M16.943 0h-7.4a1.06 1.06 0 00-1.057 1.057v7.4a1.06 1.06 0 001.057 1.057h7.4A1.06 1.06 0 0018 8.457v-7.4A1.06 1.06 0 0016.943 0zm0 8.457h-7.4v-7.4h7.4v7.4zM10.6 3.7h1.057v3.7H10.6V3.7zm2.114-1.586h1.057V7.4h-1.057V2.114zm2.115 3.172h1.057V7.4h-1.057V5.286z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M3.343 1.929h3.473v7.234c0 .88.68 1.61 1.541 1.69v1.104h1.672V10.86H11.7v1.097h1.671V10.86h1.672v6.111H8.357V13.63H6.686v3.342H0v-11.7h3.343V1.93zM1.67 15.3h1.672v-1.671H1.67V15.3zm0-3.343h1.672v-1.671H1.67v1.671zm0-3.343h1.672V6.943H1.67v1.671zm3.343 3.343h1.672v-1.671H5.014v1.671zm0-3.343h1.672V6.943H5.014v1.671zm0-3.343h1.672V3.6H5.014v1.671zM11.7 15.3h1.671v-1.671H11.7V15.3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
