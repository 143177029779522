import React from "react";
import { TextInput } from 'react-admin';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
const SearchInput = (props) => {

  return (
    <TextInput {...props} helperText={false} InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
    />
  )
}

export default SearchInput