import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import DownloadIcon from "@material-ui/icons/GetApp";
import { ListButton } from "react-admin";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
});

const LoadingButton = (props) => {
  const { classes, loading, done, children, ...other } = props;

  return (
    <ListButton
      style={{ margin: "0" }}
      className={classes.button}
      disabled={done || loading}
      label={done ? "Done" : loading ? "Generating report" : "Export to Excel"}
      icon={
        done ? (
          <CheckIcon />
        ) : loading ? (
          <CircularProgress size={20} />
        ) : (
          <DownloadIcon />
        )
      }
      {...other}
    >
      {children}
    </ListButton>
  );
};

LoadingButton.defaultProps = {
  loading: false,
  done: false,
};

LoadingButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  done: PropTypes.bool,
};

export default withStyles(styles)(LoadingButton);
