import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import React, { useState } from "react";
import {
  Button,
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  Link,
  List,
  Loading,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useGetOne,
} from "react-admin";
import { Route, Switch, useParams } from "react-router-dom";
import Empty from "../../components/Empty";
import dataProvider from "../../config/dataProvider";
import ReturnButton from "../../components/ReturnButton";
import Totals from "./totals";
import LoadingButton from "../../components/LoadingButton";
import { getCurrentFiscalYear } from "../../yearUtils";

const useStyles = makeStyles((theme) => ({
  subtitle: { marginBottom: 16 },
}));

const fullYear = getCurrentFiscalYear() + 1;

const YEAR_CHOICES = [
  { id: 1, value: fullYear },
  { id: 2, value: fullYear + 1 },
  { id: 3, value: fullYear + 2 },
  { id: 4, value: fullYear + 3 },
  { id: 4, value: fullYear + 4 },
];

const getMonthName = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
    default:
      return "Unknown";
  }
};

const reportFilters = [
  <TextInput label="GL account" source="name" variant="outlined" />,
  <TextInput label="Description" source="description" variant="outlined" />,
  <ReferenceInput label="Company" source="companyId" reference="companies">
    <SelectInput optionText="name" variant="outlined" />
  </ReferenceInput>,
  <DateInput
    label="Created date from"
    source="createdAt_gte"
    variant="outlined"
  />,
  <DateInput
    label="Created date to"
    source="createdAt_lt"
    variant="outlined"
  />,
  <SelectInput
    source="budgetYear"
    choices={YEAR_CHOICES}
    optionText={(choices) => String(choices.value)}
    optionValue="value"
    variant="outlined"
    label="Budget year"
  />,
];

const ReportListActions = (props) => {
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const handleExportToExcel = async () => {
    setLoading(true);
    try {
      await dataProvider.downloadReportCompanies(
        { ...props.filterValues, historical: false },
        props.currentSort,
        props.page,
        props.perPage
      );
      await new Promise((resolve) => setTimeout(resolve, 500));
      setFinished(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TopToolbar>
      <LoadingButton
        loading={loading}
        done={finished}
        onClick={handleExportToExcel}
      >
        Export to Excel
      </LoadingButton>
    </TopToolbar>
  );
};
const BudgetThisYearLabel = () => {
  return `Total ${fullYear}`;
};

const BudgetNext4YearsLabel = () => {
  return `Total ${fullYear + 1}-${fullYear + 4}`;
};

const TotalThisYearPlusOneLabel = () => {
  return `Total ${fullYear + 1}`;
};

const TotalThisYearPlusTwoLabel = () => {
  return `Total ${fullYear + 2}`;
};

const TotalThisYearPlusThreeLabel = () => {
  return `Total ${fullYear + 3}`;
};

const TotalThisYearPlusFourLabel = () => {
  return `Total ${fullYear + 4}`;
};

export const CompaniesReportList = (props) => {
  return (
    <Switch>
      <Route path="/companiesReports" exact>
        <ReportListInitial {...props} />
      </Route>
      <Route path="/companiesReports/:id" exact>
        <ReportListWithTotals {...props} />
      </Route>
    </Switch>
  );
};

const ReportListInitialRowButtons = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      <Link to={`/companiesReports/${props.record.id}`}>
        <Button alignIcon="left" label="Report">
          <AssessmentOutlinedIcon />
        </Button>
      </Link>
    </div>
  );
};

const ReportListInitial = (props) => {
  return (
    <List
      empty={<Empty msg="No Reports yet." />}
      {...props}
      filters={reportFilters}
      actions={<ReportListActions />}
      bulkActionButtons={false}
      filter={{ historical: false }}
    >
      <Datagrid>
        <TextField label="Company" source="name" />
        <NumberField
          emptyText="0"
          label={<BudgetThisYearLabel />}
          source="totalThisYear"
          locales="en-US"
          options={{
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol",
          }}
        />
        <NumberField
          label={<TotalThisYearPlusOneLabel />}
          source="totalNextYear"
          locales="en-US"
          options={{
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol",
          }}
        />
        <NumberField
          label={<TotalThisYearPlusTwoLabel />}
          source="totalNextYearPlusOne"
          locales="en-US"
          options={{
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol",
          }}
        />
        <NumberField
          label={<TotalThisYearPlusThreeLabel />}
          source="totalNextYearPlusTwo"
          locales="en-US"
          options={{
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol",
          }}
        />
        <NumberField
          label={<TotalThisYearPlusFourLabel />}
          source="totalNextYearPlusThree"
          locales="en-US"
          options={{
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol",
          }}
        />
        <NumberField
          label={<BudgetNext4YearsLabel />}
          source="totalNextFourYears"
          locales="en-US"
          options={{
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol",
          }}
        />
        <ReportListInitialRowButtons />
      </Datagrid>
    </List>
  );
};

const ReportListWithTotalsActions = (props) => {
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const handleExportToExcel = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await dataProvider.downloadReportByCompany(props.id);
      await new Promise((resolve) => setTimeout(resolve, 500));
      setFinished(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TopToolbar>
      <ReturnButton label={"Back to reports"} basePath={"/companiesReports"} />
      <LoadingButton
        loading={loading}
        done={finished}
        onClick={handleExportToExcel}
      >
        Export to Excel
      </LoadingButton>
    </TopToolbar>
  );
};

const ReportListWithTotals = (props) => {
  const classes = useStyles(props);
  let { id } = useParams();

  const { data, loaded } = useGetOne("companiesReports", id);

  if (!loaded) return <Loading />;
  return (
    <>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {`${data.name} - ${data.description || "N/A"} `}
      </Typography>

      <ReportListWithTotalsActions id={id} />

      <Totals {...props} data={data} />

      <List
        {...props}
        title="Companies Reports"
        basePath="/companiesReports"
        resource="budgetItems"
        filter={{ "AssetAccount--CompanyId": id, historical: false }}
        actions={false}
        bulkActionButtons={false}
      >
        <Datagrid>
          <ReferenceField
            link={false}
            label="GL account"
            source="assetAccountId"
            reference="assetAccounts"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField label="Budget Item Name" source="name" />
          <NumberField label="Year" source="year" />
          <FunctionField
            source="Month"
            render={(record) => getMonthName(record.month)}
          />
          <NumberField
            label="Amount"
            source="value"
            locales="en-US"
            options={{
              style: "currency",
              currency: "USD",
              currencyDisplay: "narrowSymbol",
            }}
          />
          <ReferenceField
            link={false}
            label="Department"
            source="departmentId"
            reference="departments"
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField
            label="Created at"
            source="createdAt"
            locale="en-US"
            options={{
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            }}
          />
        </Datagrid>
      </List>
    </>
  );
};
